<template>
  <v-container>
    <v-card style="padding: 15px">
      <v-row>
        <v-col
          cols="6"
        >
          <v-menu
            ref="menu1"
            v-model="menu1"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date"
                label="Select Date"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="date"
              no-title
              @input="menu1 = false"
            />
          </v-menu>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          v-for="item in items"
          :key="item.id"
          cols="12"
          md="3"
        >
          <v-card
            class="mx-auto"
            max-width="344"
            outlined
          >
            <v-card-text>
              <div>Room</div>
              <p class="display-1 text--primary">
                {{ item.roomPrefix + item.roomNumber }}
              </p>
              Description
              <div class="text--primary">
                {{ item.description }}
              </div>
              <br>
              Status
              <div
                v-if="item.status === 'free'"
                class="text--primary"
              >
                <span style="color: #4caf50">
                  Free
                </span>
              </div>
              <div
                v-else-if="item.status === 'busy'"
                class="text--primary"
              >
                <span style="color: red">
                  Busy
                </span>
              </div>
              <div
                v-else
                class="text--primary"
              >
                <span>
                  Unknown
                </span>
              </div>
            </v-card-text>
            <v-card-actions>
              <v-btn
                :disabled="!date"
                text
                color="success"
                @click="dialog = true; selectedRoomPrefix = item.roomPrefix; selectedRoomNumber = item.roomNumber; resetTimeIntervals(); openRoom()"
              >
                Book room
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
    <v-dialog
      v-model="dialog"
      persistent
      :scrollable="false"
      max-width="50vw"
      style="overflow: hidden"
    >
      <v-card>
        <v-card-title class="headline">
          {{ 'Room ' + selectedRoomPrefix + selectedRoomNumber }}
        </v-card-title>
        <v-card-text>Choose a time interval:</v-card-text>
        <v-row
          justify="center"
          class="pa-0"
          style="margin-left: 10px"
        >
          <v-col
            v-for="(time, i) in timeIntervals"
            :key="'item-' + i"
            cols="12"
            md="2"
            lg="1"
            sm="6"
            class="text-center justify-center align-center"
            @click="reserver(i)"
          >
            <span
              v-if="timeOccupied[i] === false"
              style="color: green"
              @click="selectedInterval = timeIntervals[i]; activateReservation = true; activateDetails = false; getRecentReservation(i)"
            >
              {{ time }}
            </span>
            <span
              v-else
              style="color: red"
              @click="selectedInterval = timeIntervals[i]; activateDeletion = true; deletionIndex = i; getReservation(i)"
            >
              {{ time }}
            </span>
          </v-col>
        </v-row>
        <v-row
          v-if="activateReservation"
          justify="center"
          class="pa-0"
          style="margin-left: 10px"
        >
          <span class="display-2">
            {{ 'You are attempting to reserve room ' + selectedRoomPrefix + selectedRoomNumber + ' for the interval ' + selectedInterval }}
          </span>
        </v-row>
        <v-row
          v-else-if="activateDeletion"
          justify="center"
          class="pa-0"
          style="margin-left: 10px"
        >
          <span class="display-2">
            {{ 'You are attempting to delete reservation for room ' + selectedRoomPrefix + selectedRoomNumber + ' for the interval ' + selectedInterval }}
          </span>
        </v-row>
        <v-row
          v-if="activateDetails && reservationDetails.roomPrefix && reservationDetails.roomNumber"
          justify="center"
          class="pa-0"
          style="margin-left: 10px"
        >
          <span class="display-1">
            {{ 'Room: ' + reservationDetails.roomPrefix + reservationDetails.roomNumber }}
          </span>
        </v-row>
        <v-row
          v-if="activateDetails && reservationDetails.userID"
          justify="center"
          class="pa-0"
          style="margin-left: 10px"
        >
          <span class="display-1">
            {{ 'Reserved by: ' + reservationDetails.userID }}
          </span>
        </v-row>
        <v-row
          v-if="activateDetails && reservationDetails.startTimestamp"
          justify="center"
          class="pa-0"
          style="margin-left: 10px"
        >
          <span class="display-1">
            {{ 'Start Time: ' + new Date(Math.floor(reservationDetails.startTimestamp * 1000)).toLocaleTimeString() }}
          </span>
        </v-row>
        <v-row
          v-if="activateDetails && reservationDetails.endTimestamp"
          justify="center"
          class="pa-0"
          style="margin-left: 10px"
        >
          <span class="display-1">
            {{ 'End Time: ' + new Date(Math.floor(reservationDetails.endTimestamp * 1000)).toLocaleTimeString() }}
          </span>
        </v-row>
        <v-row
          v-if="activateDetails && reservationDetails.queueUsers && reservationDetails.queueUsers.length !== 0"
          justify="center"
          class="pa-0"
          style="margin-left: 10px"
        >
          <span class="display-1">
            {{ 'Users in queue: ' + reservationDetails.queueUsers }}
          </span>
        </v-row>
        <v-card-actions>
          <v-spacer />
          <v-btn
            v-if="activateDeletion"
            color="green darken-1"
            text
            @click="dialog = false; addToQueue(deletionIndex); activateReservation = false; activateDeletion = false; deletionIndex = -1; selectedInterval = ''; activateDetails = false"
          >
            Notify Me
          </v-btn>
          <v-btn
            v-if="activateDeletion"
            color="green darken-1"
            text
            @click="dialog = false; deleteReservation(deletionIndex); activateReservation = false; activateDeletion = false; deletionIndex = -1; selectedInterval = ''; activateDetails = false"
          >
            Delete
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="dialog = false; activateReservation = false; activateDeletion = false; deletionIndex = -1; selectedInterval = ''; activateDetails = false"
          >
            Discard
          </v-btn>
          <v-btn
            :disabled="!selectedInterval || $store.getters.get_permissionLevel < 1"
            color="green darken-1"
            text
            @click="dialog = false; doTheJob(); activateReservation = false; activateDeletion = false; deletionIndex = -1; selectedInterval = ''; activateDetails = false; getRooms()"
          >
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import axios from 'axios'
  import router from '@/router'
  const API_PATH = 'https://mps2-backend.herokuapp.com/api'

  export default {
    name: 'Rooms',
    data () {
      return {
        items: [],
        dialog: false,
        selectedRoomPrefix: '',
        selectedRoomNumber: 0,
        selectedInterval: '',
        timeIntervals: ['8-10', '10-12', '12-14', '14-16', '16-18', '18-20', '20-22'],
        timeOccupied: [false, false, false, false, false, false, false],
        idOccupied: ['', '', '', '', '', '', ''],
        makeReservation: [false, false, false, false, false, false, false],
        date: null,
        menu1: false,
        activateReservation: false,
        activateDeletion: false,
        deletionIndex: -1,
        reservationDetails: {},
        activateDetails: false,
      }
    },

    watch: {
      menu (val) {
        val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
      },
    },

    beforeMount () {
      if (!this.$store.getters.get_username) router.push('/pages/login')
    },

    mounted () {
      this.getRooms()
    },

    methods: {
      getRooms () {
        axios
          .get(API_PATH + '/getRooms')
          .then((res) => {
            this.items = res.data.message
          })
          .catch((error) => {
            this.error_message = error.response.data.message
            this.status_error = true
          })
      },
      save (date) {
        this.$refs.menu.save(date)
      },
      openRoom () {
        // console.log(this.date)
        axios
          .get(API_PATH + '/getIntervals?timestamp=' + Math.round(new Date(this.date).getTime() / 1000) + '&roomPrefix=' +
            this.selectedRoomPrefix + '&roomNumber=' + this.selectedRoomNumber)
          .then((res) => {
            this.timeOccupied = res.data.message
            this.idOccupied = res.data.idList
            // console.log(res.data)
          })
          .catch((error) => {
            this.error_message = error.response.data.message
            this.status_error = true
          })
      },
      resetTimeIntervals () {
        this.timeOccupied = [false, false, false, false, false, false, false]
      },
      reserver (i) {
        if (this.timeOccupied[i]) { this.makeReservation[i] = true }
      },
      startReserving () {
        var updated = Math.floor(new Date(this.date).getTime() / 1000)
        var index = this.timeIntervals.indexOf(this.selectedInterval)
        var hour = (index + 3) * 2
        updated += hour * 3600
        var end = updated + 7200
        axios
          .get(API_PATH + '/insertReservation?st=' + updated + '&et=' + end + '&roomPrefix=' +
            this.selectedRoomPrefix + '&roomNumber=' + this.selectedRoomNumber + '&uid=' + this.$store.getters.get_username)
          .then((res) => {
            // console.log(res.data)
            this.selectedInterval = ''
          })
          .catch((error) => {
            this.error_message = error.response.data.message
          })
      },
      deleteReservation (index) {
        axios
          .get(API_PATH + '/deleteReservation?id=' + this.idOccupied[index])
          .then((res) => {
            // console.log(res.data)
            this.selectedInterval = ''
          })
          .catch((error) => {
            this.error_message = error.response.data.message
          })
      },

      doTheJob () {
        if (this.activateReservation) this.startReserving()
        else if (this.activateDeletion && this.deletionIndex !== -1) this.deleteReservation(this.deletionIndex)
      },

      addToQueue (index) {
        axios
          .get(API_PATH + '/addToQueue?id=' + this.idOccupied[index] + '&username=' + this.$store.getters.get_username)
          .then((res) => {
            // console.log(res.data)
          })
          .catch((error) => {
            this.error_message = error.response.data.message
          })
      },
      getReservation (index) {
        this.activateDetails = true
        axios
          .get(API_PATH + '/getReservation?id=' + this.idOccupied[index])
          .then((res) => {
            this.reservationDetails = res.data.message
          })
          .catch((error) => {
            this.error_message = error.response.data.message
            this.status_error = true
          })
      },
      getRecentReservation (index) {
        if (this.selectedRoomNumber && this.selectedRoomPrefix) {
          this.activateDetails = true
          axios
            .get(API_PATH + '/getRecentReservation?roomPrefix=' + this.selectedRoomPrefix + '&roomNumber=' + this.selectedRoomNumber)
            .then((res) => {
              this.reservationDetails = res.data.message ? res.data.message : {}
            })
            .catch((error) => {
              this.error_message = error.response.data.message
              this.status_error = true
            })
        }
      },
    },
  }
</script>
